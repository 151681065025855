package Data.Localisation

internal object LocalisationDataDE : LocalisationData() {
    // General
    override val aboutMe = "Über Mich"
    override val aboutMeContent = "" +
            "Vorname:\tDustyn\n" +
            "Nachname:\tBertelt\n" +
            "Email:\t\tdustyn.bertelt@future-laboratories.io\n" +
            ""
    override val homepage =
        "Diese Website ist Work in Progress, gebaut mit jetpack compose für Web. In Zukunft werde ich hier meine Projekte vorstellen!"
    override val partner = "Geschäftsbeziehung"
    override val projects = "Projekte"

    // BlackHoleBomb
    override val bhbDescription = "" +
            "Beschreibung: Dies ist ein Nebenprojekt von mir, hierbei handelt es sich um ein Sci-fi Städte Aufbauspiel das in einer weit entfernten Zukunft spielt\n" +
            "in der der letzte Zufluchtspunkt der Menschheit die Umlaufbahn eines schwarzen Loch ist.\n" +
            "[Dieses Projekt ist W.I.P]\n" +
            ""

    // FutureLabStories
    override val flsDescription = "Dieses Projekt ist aktuell pausiert"
}
