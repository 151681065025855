import org.jetbrains.compose.web.css.CSSBuilder
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.px
import kotlin.reflect.KProperty

inline fun <reified T> getCSSName(style: KProperty<String>): String = "${T::class.simpleName}-${style.name}"

// region border-radius
fun CSSBuilder.borderRadius(value: CSSSizeValue<out CSSUnit> = 0.px, type: Type = Type.Custom) {
    val assembledValue = when(type) {
        Type.Inherit -> "inherit"
        Type.Custom -> "${value.value}${value.unit}"
    }

    property("border-radius", assembledValue)
}

enum class Type {
    Inherit,
    Custom,
}

// endregion

// region verticalAlign
fun CSSBuilder.verticalAlign(align: VerticalAlign) {
    property("vertical-align", align.value)
}

fun StyleScope.verticalAlign(align: VerticalAlign) {
    property("vertical-align", align.value)
}

enum class VerticalAlign(val value: String) {
    Top("top"),
    Middle("middle"),
    Bottom("bottom"),
    Baseline("baseline"),
}

// endregion

// region box-shadow

data class BoxShadow(
    val x: CSSSizeValue<out CSSUnit> = 0.px,
    val y: CSSSizeValue<out CSSUnit> = 0.px,
    val blur: CSSSizeValue<out CSSUnit> = 0.px,
    val spread: CSSSizeValue<out CSSUnit> = 0.px,
    val color: CSSColorValue = Color.transparent,
) {
    val defaultBuilder =
        "${x.value}${x.unit} ${y.value}${y.unit} ${blur.value}${blur.unit} ${spread.value}${spread.unit} $color"
}

fun StyleScope.boxShadow(
    inset: Boolean = false,
    x: CSSSizeValue<out CSSUnit> = 0.px,
    y: CSSSizeValue<out CSSUnit> = 0.px,
    blur: CSSSizeValue<out CSSUnit> = 0.px,
    spread: CSSSizeValue<out CSSUnit> = 0.px,
    color: CSSColorValue = Color.transparent,
) {
    property(
        "box-shadow",
        "${if (inset) "inset" else ""} ${x.value}${x.unit} ${y.value}${y.unit} ${blur.value}${blur.unit} ${spread.value}${spread.unit} $color"
    )
}

fun StyleScope.boxShadow(inset: Boolean = false, vararg shadows: BoxShadow) {
    property(
        "box-shadow",
        shadows.joinToString { "${if (inset) "inset " else ""} ${it.defaultBuilder}" }
    )
}

// endregion

// region fontFamilies
fun CSSBuilder.fontFamily(font: Fonts) {
    fontFamily(font.value)
}

enum class Fonts(val value: String) {
    NunitoSans("Nunito Sans")
}
// endregion

// region floats
fun CSSBuilder.float(float: Floats) {
    property("float", float.value)
}

fun StyleScope.float(float: Floats) {
    property("float", float.value)
}

enum class Floats(val value: String) {
    None("none"),
    Left("left"),
    Right("right"),
}
// endregion

// region z-index
fun CSSBuilder.zIndex(index: Int) {
    property("z-index", index)
}
// endregion

//region transition
fun CSSBuilder.transitionDuration(duration: CSSSizeValue<out CSSUnit>) {
    property("transition-duration", duration)
}
//endregion