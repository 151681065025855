package Data.Localisation

abstract class LocalisationData {
    // General
    abstract val aboutMe: String
    abstract val aboutMeContent: String
    abstract val homepage: String
    abstract val partner: String
    abstract val projects: String

    // BlackHoleBomb
    abstract val bhbDescription: String

    // FutureLabStories
    abstract val flsDescription: String

    val mappedLanguage by lazy {
        mapOf(
            // General
            "aboutMe" to aboutMe,
            "aboutMeContent" to aboutMeContent,
            "homepage" to homepage,
            "partner" to partner,
            "projects" to projects,

            // BlackHoleBomb
            "bhbDescription" to bhbDescription,

            // FutureLabStories
            "flsDescription" to flsDescription,
        )
    }
}