package Data.Localisation

object LocalisationDataEN : LocalisationData() {
    // General
    override val aboutMe = "About Me"
    override val aboutMeContent = "" +
            "first name:\tDustyn\n" +
            "surname:\tBertelt\n" +
            "Email:\t\tdustyn.bertelt@future-laboratories.io\n" +
            ""
    override val homepage =
        "This is a work in progress webpage build with jetpack compose for web. In the future I will present my current projects on this page!"
    override val partner = "Business Relation"
    override val projects = "Projects"

    // BlackHoleBomb
    override val bhbDescription = "" +
            "Description: This is a side project of mine. It's a sci-fi city-building game set in a distant future\n" +
            "where the last refuge of humanity is the orbit of a black hole.\n" +
            "[This project is W.I.P]\n" +
            ""

    // FutureLabStories
    override val flsDescription = "This project is currently on hold"
}