package Data.Page

fun Pages.getUnifiedString() = "#${this.toString().lowercase()}"

enum class Pages(val pageName: String) {
    //Single Pages
    Home("#home"),
    AboutMe("#aboutme"),

    // Dropdown Pages
    SmartMemo("#smartmemo"),
    FutureLabStories("#futurelabstories"),
    ProjectBlackHoleBomb("#projectblackholebomb"),
}