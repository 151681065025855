package Data.Header

import Data.Localisation.LocalisationBuilder.getLocalisedString
import Data.Page.Pages
import Data.Page.getUnifiedString
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.px

object HeaderData {
    val refs = listOf(
        Reference.UriReference(Pages.Home.getUnifiedString(), "Homepage", 80.px),
        Reference.DropDown(
            getLocalisedString("projects"),
            100.px,
            //Reference.UriReference(Pages.SmartMemo.getUnifiedString(), "SmartMemo"),
            Reference.UriReference(Pages.ProjectBlackHoleBomb.getUnifiedString(), "Project Black Hole Bomb"),
            Reference.UriReference(Pages.FutureLabStories.getUnifiedString(), "FutureLab-Stories"),
        ),
        Reference.DropDown(
            getLocalisedString("partner"),
            165.px,
            Reference.UriReference("https://www.hetzner.com/de", "Host"),
            Reference.UriReference("https://wattn-blog.de/", "Blog"),
        ),
        Reference.UriReference(
            uri = Pages.AboutMe.getUnifiedString(),
            name = getLocalisedString("aboutMe"),
            width = 70.px,
        ),
        Reference.UriReference("https://github.com/Future-Laboratories", "Github", 50.px),
    )

    sealed class Reference(var width: CSSSizeValue<out CSSUnit>? = null) {
        class DropDown(
            var title: String,
            width: CSSSizeValue<out CSSUnit>? = null,
            vararg val references: SingleReference,
        ) : Reference(width)

        class UriReference(var uri: String, var name: String, width: CSSSizeValue<out CSSUnit>? = null) :
            Reference(width), SingleReference

        class ClickReference(var name: String, width: CSSSizeValue<out CSSUnit>? = null, var onClick: () -> Unit) :
            Reference(width), SingleReference

        sealed interface SingleReference
    }
}