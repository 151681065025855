package Pages

import Composable.styledParagraph
import ContentModules.textInImage
import Data.Localisation.LocalisationBuilder
import StyleSheets.DefaultStyle
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.browser.window
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.minHeight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign

@Composable
fun homePage() {
    Style(DefaultStyle)

    // 0.6 is a magic number
    var maxSize by mutableStateOf(window.innerHeight * 0.6)
    window.addEventListener("resize", {
        maxSize = window.innerHeight * 0.6
    })

    textInImage("images/preview.webp", "Future-Laboratories.io")
    styledParagraph(
        text = LocalisationBuilder.getLocalisedString("homepage")
    ) {
        textAlign("center")
        minHeight(maxSize.px)
    }
}