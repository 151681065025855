package Defaults

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba

val primaryLight = rgb(0, 204, 255)
val secondaryLight = rgb(0, 187, 229)
val tertiaryLight = rgb(0, 163, 205)
val shadowColorLight = rgba(220, 220, 220, 0.4)

val primaryDark = rgb(3, 149, 178)
val secondaryDark = rgb(0, 104, 127)
val tertiaryDark = rgb(0, 80, 103)
val shadowColorDark = rgba(34, 34, 34, 0.3)
val customGray = rgb(50, 50, 50)

object DefaultThemedColors : DefaultBase() {
    var primaryColor: CSSColorValue by mutableStateOf(primaryDark)
    var secondaryColor: CSSColorValue by mutableStateOf(secondaryDark)
    var tertiaryColor: CSSColorValue by mutableStateOf(tertiaryDark)
    var textColor: CSSColorValue by mutableStateOf(Color.white)
    var backgroundColor: CSSColorValue by mutableStateOf(customGray)
    var shadowColor: CSSColorValue by mutableStateOf(shadowColorDark)

    init {
        onChange()
    }

    override fun onChange() = updateTheme()

    private fun updateTheme() {
        if (query.matches) {
            this.primaryColor = primaryDark
            this.secondaryColor = secondaryDark
            this.tertiaryColor = tertiaryDark
            this.textColor = Color.white
            this.backgroundColor = customGray
            this.shadowColor = shadowColorDark
        } else {
            this.primaryColor = primaryLight
            this.secondaryColor = secondaryLight
            this.tertiaryColor = tertiaryLight
            this.textColor = Color.black
            this.backgroundColor = Color.white
            this.shadowColor = shadowColorLight
        }
    }

    val transparentGray = rgba(80,80,80, 0.5)
}
