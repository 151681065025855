package Composable

import Composable.ParagraphStyle.paragraphBlock
import Fonts
import androidx.compose.runtime.Composable
import fontFamily
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.media
import org.jetbrains.compose.web.css.mediaMaxWidth
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

object ParagraphStyle : StyleSheet() {
    val paragraphBlock by style {
        //property("hyphens", auto)
        textAlign("justify")
        whiteSpace("pre-wrap")

        marginLeft(20.percent)
        marginRight(20.percent)

        media(
            query = mediaMaxWidth(768.px)
        ) {
            self style {
                marginLeft(5.percent)
                marginRight(5.percent)
            }
        }

        fontFamily(Fonts.NunitoSans)
    }
}

@Composable
fun styledParagraph(
    text: String,
    style: (StyleScope.() -> Unit)? = null,
) {
    Style(ParagraphStyle)

    P(
        attrs = {
            classes(paragraphBlock)

            style {
                if (style != null) {
                    style(this)
                }
            }
        }
    ) {
        Text(text)
    }
}