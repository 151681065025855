package Pages

import Composable.styledParagraph
import ContentModules.textInImage
import Data.Localisation.LocalisationBuilder.getLocalisedString
import StyleSheets.DefaultStyle
import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.dom.Br

@Composable
fun futureLabStoriesPage() {
    textInImage("images/preview.webp", "Future-Laboratories.io")

    Br()

    Style(DefaultStyle)
    styledParagraph(
        text = getLocalisedString("flsDescription")
    )

    /*imageToText(
        image = "images/preview.webp",
        imageSize = 20.vw,
        additionalStyle = {
            backgroundColor(DefaultThemedColors.backgroundColor)
            boxShadow(
                inset = false,
                BoxShadow(
                    y = 2.px,
                    blur = 20.px,
                    color = DefaultThemedColors.shadowColor,
                ),
                BoxShadow(
                    y = (-2).px,
                    blur = 20.px,
                    color = DefaultThemedColors.shadowColor,
                ),
            )
        }
    ) {
        H1 {
            Text(value = "mockTextLong")
        }
    }*/
}