package Defaults

import kotlinx.browser.window

abstract class DefaultBase {
    internal val query = window.matchMedia("(prefers-color-scheme: dark)")

    init {
        query.onchange = {
            onChange()
        }
    }

    abstract fun onChange()
}