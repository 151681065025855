package Defaults

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue

object DefaultThemedDrawables : DefaultBase() {
    var dropDownSVG by mutableStateOf("chevron-down-black.svg")

    init {
        onChange()
    }

    override fun onChange() = updateDrawables()

    private fun updateDrawables() {
        dropDownSVG = if (query.matches) {
            dropDownWhite
        } else {
            dropDownBlack
        }
    }

    private const val dropDownBlack = "chevron-down-black.svg"
    private const val dropDownWhite = "chevron-down-white.svg"
}