import Composable.styledDropDown
import Composable.styledSingleLink
import Composable.toStyledOption
import Data.Header.HeaderData
import Data.Header.HeaderData.refs
import Data.Page.Pages
import Defaults.DefaultThemedColors
import Pages.aboutMePage
import Pages.blackHoleBombPage
import Pages.futureLabStoriesPage
import Pages.homePage
import Pages.smartMemoPage
import StyleSheets.DefaultStyle
import StyleSheets.DefaultStyle.baseHeader
import StyleSheets.DefaultStyle.body
import StyleSheets.DefaultStyle.footer
import StyleSheets.DefaultStyle.sideNav
import StyleSheets.DefaultStyle.stickyHeader
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.browser.window
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.renderComposable

fun main() {
    var page by mutableStateOf(Pages.FutureLabStories)

    fun checkCurrentPage() {
        page = when (window.location.hash) {
            Pages.SmartMemo.pageName -> Pages.SmartMemo
            Pages.ProjectBlackHoleBomb.pageName -> Pages.ProjectBlackHoleBomb
            Pages.FutureLabStories.pageName -> Pages.FutureLabStories
            Pages.AboutMe.pageName -> Pages.AboutMe
            else -> Pages.Home
        }
    }

    window.onhashchange = {
        checkCurrentPage()
    }
    checkCurrentPage()

    renderComposable(rootElementId = "root_header") {
        @Composable
        fun menuData(width: CSSSizeValue<out CSSUnit>? = null) = refs.forEach {
            when (it) {
                is HeaderData.Reference.DropDown -> {
                    styledDropDown(
                        title = it.title,
                        width = width ?: it.width ?: 80.px,
                    ) {
                        it.references.forEachIndexed { index, ref ->
                            ref.toStyledOption(index == it.references.lastIndex)
                        }
                    }
                }

                is HeaderData.Reference.UriReference, is HeaderData.Reference.ClickReference -> styledSingleLink(
                    reference = it,
                    width = width ?: it.width ?: 80.px,
                )
            }
        }

        var sideMenuVisible by mutableStateOf(false)
        // 100 is an approx. magic number
        val showSideMenu: Int = refs.sumOf { it.width?.value?.toInt() ?: 0 } + 100
        val sideMenuData = @Composable {
            val width = if (sideMenuVisible) 250.px else 0.px
            Div(attrs = {
                classes(sideNav)
                style {
                    width(width)
                    height(100.percent)

                    backgroundColor(DefaultThemedColors.backgroundColor)
                    boxShadow(
                        x = 3.px,
                        blur = 3.px,
                        color = DefaultThemedColors.shadowColor
                    )
                }
            }) {
                Span(attrs = {
                    style {
                        fontSize(1.6f.cssRem)
                        cursor("pointer")

                        position(Position.Absolute)
                        top(0.px)
                        right(25.px)
                        marginLeft(50.px)
                    }

                    onClick {
                        sideMenuVisible = false
                    }
                }) {
                    Text("x")
                }
                menuData(width = width)
            }
        }

        // Set general Style
        Style(DefaultStyle)

        Div(attrs = {
            classes(baseHeader, stickyHeader)
            style {
                backgroundColor(DefaultThemedColors.backgroundColor)
                color(DefaultThemedColors.textColor)

                boxShadow(
                    y = 3.px,
                    blur = 3.px,
                    color = DefaultThemedColors.shadowColor,
                )
            }
        }) {
            var innerHeight by mutableStateOf(window.innerWidth)
            window.addEventListener("resize", {
                innerHeight = window.innerWidth
            })
            if (innerHeight > showSideMenu) {
                menuData()
            } else {
                Span(attrs = {
                    style {
                        fontSize(1.6f.cssRem)
                        paddingLeft(10.px)
                    }
                    onClick {
                        sideMenuVisible = true
                    }
                }) {
                    Text("☰")
                }

                sideMenuData()
            }
        }
    }

    renderComposable(rootElementId = "root_body") {
        // Set general Style
        Style(DefaultStyle)

        Div(attrs = {
            classes(body)
            style {
                backgroundColor(DefaultThemedColors.backgroundColor)
                color(DefaultThemedColors.textColor)
            }
        }) {
            @Composable
            when (page) {
                Pages.SmartMemo -> smartMemoPage()
                Pages.FutureLabStories -> futureLabStoriesPage()
                Pages.ProjectBlackHoleBomb -> blackHoleBombPage()
                Pages.Home -> homePage()
                Pages.AboutMe -> aboutMePage()
            }
        }
    }

    // Footer
    renderComposable(rootElementId = "root_footer") {
        Style(DefaultStyle)
        Div(attrs = {
            classes(footer)
            style {
                backgroundColor(DefaultThemedColors.backgroundColor)
                boxShadow(
                    y = (-3).px,
                    color = DefaultThemedColors.shadowColor,
                )
                color(DefaultThemedColors.textColor)
            }
        }) {
            Span({
                style {
                    padding(15.px)
                    float(Floats.Left)
                }
            }) {
                Text("© - 2023, Dustyn Bertelt")
            }

            Div({
                style {
                    float(Floats.Right)
                    padding(15.px)
                }
            }) {
                Img(
                    src = "images/icon64x64.webp",
                    attrs = {
                        style {
                            verticalAlign(VerticalAlign.Middle)

                            height(25.px)
                            marginRight(6.px)
                        }
                    }
                )

                Span(attrs = {
                    style {
                        verticalAlign(VerticalAlign.Middle)
                    }
                }) {
                    Text("Future-Laboratories.io")
                }
            }
        }
    }
}
