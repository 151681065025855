package Pages

import Composable.styledParagraph
import Data.Localisation.LocalisationBuilder
import StyleSheets.DefaultStyle
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.browser.window
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun aboutMePage() {
    Style(DefaultStyle)

    // 0.6 is a magic number
    var maxSize by mutableStateOf(window.innerHeight * 0.6)
    window.addEventListener("resize", {
        maxSize = window.innerHeight * 0.6
    })
    styledParagraph(
        text = LocalisationBuilder.getLocalisedString("aboutMeContent"),
        style = {
            marginTop(0.px)
            paddingTop(20.percent)

            height(100.percent)
        }
    )
}