package StyleSheets

import Defaults.DefaultThemedColors
import Fonts
import fontFamily
import kotlinx.browser.window
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.minHeight
import org.jetbrains.compose.web.css.minWidth
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.textDecoration
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import transitionDuration
import zIndex

object DefaultStyle : StyleSheet() {
    // Default header Style
    val baseHeader by style {
        fontFamily(Fonts.NunitoSans)
    }

    val stickyHeader by style {
        position(Position.Fixed)
        top(0.px)
        width(100.percent)

        zIndex(1)
    }

    val sideNav by style {
        height(100.percent)
        width(0.px)
        position(Position.Fixed)
        zIndex(1)
        top(0.px)
        left(0.px)
        overflow("hidden")
        transitionDuration(500.ms)
        paddingTop(40.px)

        "A" style {
            textDecoration("none")
            display(DisplayStyle.Block)
            transitionDuration(0.3f.s)
        }
    }

    // Default body Style
    val body by style {
        textAlign("center")
        paddingBottom(15.px)

        minHeight(window.innerHeight.px)
    }

    // default footer style
    val footer by style {
        position(Position.Absolute)
        bottom(auto)

        minWidth(100.percent)

        color(DefaultThemedColors.textColor)

        fontFamily(Fonts.NunitoSans)
    }

    init {
        // override body to remove margin
        "body" style {
            margin(0.px)
            minHeight(100.percent)
        }
    }
}
