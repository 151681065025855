package ContentModules

import ContentModules.TextInImageStyle.overlayContainer
import ContentModules.TextInImageStyle.overlayText
import Defaults.DefaultThemedColors
import Fonts
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import boxShadow
import fontFamily
import kotlinx.browser.window
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.transform
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

object TextInImageStyle : StyleSheet() {
    // region TextImage
    val overlayContainer by style {
        textAlign("center")
        position(Position.Relative)
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val overlayText by style {
        color(Color.black)
        fontSize(2.2f.vw)

        position(Position.Absolute)
        top(50.percent)
        left(50.percent)
        transform {
            translate((-50).percent, (-50).percent)
        }

        fontFamily(Fonts.NunitoSans)
    }
// endregion
}

@Composable
fun textInImage(
    imagePath: String,
    text: String,
    textColor: CSSColorValue = Color.black,
) {
    var maxSize by mutableStateOf(window.outerHeight * 0.35)
    window.addEventListener("resize", {
        maxSize = window.outerHeight * 0.35
    })

    Style(TextInImageStyle)
    Div(attrs = { classes(overlayContainer) }) {
        Img(
            src = imagePath
        ) {
            style {
                maxHeight(maxSize.px)
                width(100.percent)
                boxShadow(
                    y = 5.px,
                    blur = 5.px,
                    color = DefaultThemedColors.shadowColor
                )

                property("object-fit", "cover")
            }
        }
        Div(attrs = {
            classes(overlayText)

            style {
                color(textColor)
            }
        }) {
            H1 {
                Text(
                    value = text,
                )
            }
        }
    }
}