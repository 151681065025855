package Data.Localisation

import kotlinx.browser.window

object LocalisationBuilder {
    private val mappedLocalisations = mapOf(
        "de" to LocalisationDataDE.mappedLanguage,
        "en" to LocalisationDataEN.mappedLanguage,
    ).withDefault { LocalisationDataEN.mappedLanguage }

    fun getLocalisedString(key: String) = mappedLocalisations.getValue(window.navigator.language.take(2))[key]
        ?: throw NoSuchElementException("No element for $key")
}