package Pages

import Composable.styledParagraph
import ContentModules.textInImage
import Data.Localisation.LocalisationBuilder.getLocalisedString
import StyleSheets.DefaultStyle
import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.dom.Br

@Composable
fun blackHoleBombPage() {
    textInImage("images/preview_bhb.webp", "Future-Laboratories.io", Color.dodgerblue)

    Br()

    Style(DefaultStyle)

    styledParagraph(
        text = getLocalisedString("bhbDescription")
    )
}