package Composable

import Composable.DefaultDropDownStyle.SingleLink
import Composable.DefaultDropDownStyle.dropDown
import Composable.DefaultDropDownStyle.dropDownBtn
import Composable.DefaultDropDownStyle.dropDownContent
import Composable.DefaultDropDownStyle.dropDownLink
import Data.Header.HeaderData
import Defaults.DefaultThemedColors
import Defaults.DefaultThemedColors.transparentGray
import Defaults.DefaultThemedDrawables.dropDownSVG
import Floats
import VerticalAlign
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import boxShadow
import float
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.outline
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.textDecoration
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLAnchorElement
import transitionDuration
import verticalAlign
import zIndex

object DefaultDropDownStyle : StyleSheet() {
    val dropDown by style {
        float(Floats.Left)
    }

    val dropDownBtn by style {
        transitionDuration(300.ms)
        outline("none")
        border(0.px)
        margin(0.px)

        textAlign("center")

        padding(10.px)
    }

    val dropDownContent by style {
        float(Floats.None)
        display(DisplayStyle.Block)
        position(Position.Absolute)

        textAlign("left")

        zIndex(1)
    }

    val dropDownLink by style {
        display(DisplayStyle.Block)
        textAlign("left")
        float(Floats.None)

        property("color", "inherit")
        textDecoration("none")

        fontSize(0.8.cssRem)

        padding(10.px)
    }

    val SingleLink by style {
        fontSize(0.8.cssRem)
        transitionDuration(300.ms)
        property("color", "inherit")
        textDecoration("none")
    }
}

@Composable
fun styledDropDownButton(
    title: String,
    width: CSSSizeValue<out CSSUnit> = 0.px,
    colorRef: CSSColorValue,
) {
    Button(
        attrs = {
            classes(dropDownBtn)

            style {
                height(38.px)
                color(DefaultThemedColors.textColor)
                backgroundColor(colorRef)

                if (width.value > 0) {
                    width(width)
                }
            }
        }
    ) {
        Text(
            value = title
        )

        Img(
            src = "images/$dropDownSVG",
            attrs = {
                style {
                    marginLeft(8.px)
                    height(0.75.cssRem)

                    verticalAlign(VerticalAlign.Middle)
                }
            }
        )
    }
}

@Composable
fun styledDropDown(
    title: String,
    width: CSSSizeValue<out CSSUnit> = 0.px,
    elementScope: @Composable () -> Unit,
) {
    var currentColor by mutableStateOf(DefaultThemedColors.backgroundColor)
    var extended by mutableStateOf(false)

    Style(DefaultDropDownStyle)
    Div(
        attrs = {
            classes(dropDown)

            if (width.value > 0) {
                style {
                    width(width)
                }
            }

            onMouseEnter {
                extended = true
                currentColor = DefaultThemedColors.primaryColor
            }

            onMouseLeave {
                extended = false
                currentColor = DefaultThemedColors.backgroundColor
            }
        }
    ) {
        styledDropDownButton(
            title = title,
            width = width,
            colorRef = currentColor,
        )

        if (extended) {
            Div(
                attrs = {
                    classes(dropDownContent)

                    style {
                        color(DefaultThemedColors.textColor)
                        backgroundColor(DefaultThemedColors.backgroundColor)

                        if (width.value > 0) {
                            width(width)
                        }

                        borderRadius(
                            topLeft = 0.px,
                            topRight = 0.px,
                            bottomRight = 10.px,
                            bottomLeft = 10.px,
                        )

                        boxShadow(
                            x = 3.px,
                            y = 3.px,
                            blur = 3.px,
                            color = DefaultThemedColors.shadowColor
                        )
                    }
                }
            ) {
                elementScope()
            }
        }
    }
}

@Composable
fun styledOption(
    reference: HeaderData.Reference.SingleReference,
    lastElement: Boolean,
) {
    var currentColor by mutableStateOf(Color.transparent)

    val attrs: AttrBuilderContext<HTMLAnchorElement> = {
        classes(dropDownLink)

        style {
            backgroundColor(currentColor)
            if (lastElement) {
                property("border-radius", "inherit")
            }
        }

        onMouseEnter {
            currentColor = transparentGray
        }

        onMouseLeave {
            currentColor = Color.transparent
        }
    }

    when (reference) {
        is HeaderData.Reference.ClickReference -> {
            A(
                attrs = {
                    attrs()
                    onClick { reference.onClick() }
                }
            ) {
                Text(
                    value = reference.name,
                )
            }
        }

        is HeaderData.Reference.UriReference -> A(
            href = reference.uri,
            attrs = {
                attrs()
            }
        ) {
            Text(
                value = reference.name,
            )
        }
    }
}

@Composable
fun styledSingleLink(
    reference: HeaderData.Reference,
    width: CSSSizeValue<out CSSUnit> = 0.px,
) {
    var currentColor by mutableStateOf(DefaultThemedColors.backgroundColor)

    val attrs: AttrBuilderContext<HTMLAnchorElement> = {
        classes(dropDown, dropDownBtn, SingleLink)

        style {
            color(DefaultThemedColors.textColor)
            backgroundColor(currentColor)

            if (width.value > 0) {
                width(width)
            }
        }

        onMouseEnter {
            currentColor = DefaultThemedColors.primaryColor
        }

        onMouseLeave {
            currentColor = DefaultThemedColors.backgroundColor
        }
    }

    Style(DefaultDropDownStyle)

    when (reference) {
        is HeaderData.Reference.ClickReference -> {
            A(
                attrs = {
                    attrs()

                    onClick { reference.onClick() }
                },
            ) {
                Text(
                    value = reference.name,
                )
            }
        }

        is HeaderData.Reference.UriReference -> {
            A(
                href = reference.uri,
                attrs = {
                    attrs()

                    classes(SingleLink)
                },
            ) {
                Text(
                    value = reference.name,
                )
            }
        }

        else -> throw Exception("Call styledDropDown Instead")
    }
}

@Composable
fun HeaderData.Reference.SingleReference.toStyledOption(last: Boolean) = styledOption(this, last)
